import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`5th March 2025`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Load Balancer`}</strong>{`: You can now create a `}<a parentName="li" {...{
          "href": "type://LoadBalancer:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/loadbalancer/LoadBalancer.html"
        }}>{`type://LoadBalancer`}</a>{` using various load balancing strategies to
select an element from a list of candidates. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5289"
        }}>{`#5289`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5779"
        }}>{`#5779`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`List<Endpoint> candidates = ...;
LoadBalancer.ofRoundRobin(candidates);
LoadBalancer.ofWeightedRoundRobin(candidates);
LoadBalancer.ofSticky(candidates, contextHasher);
LoadBalancer.ofWeightedRandom(candidates);
LoadBalancer.ofRampingUp(candidates);
`}</code></pre>
      </li>
      <li parentName="ul"><strong parentName="li">{`Filter Invalid IP address`}</strong>{`: You can now reject invalid IP addresses using
`}<a parentName="li" {...{
          "href": "type://ClientFactoryBuilder#ipAddressFilter(Predicate):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#ipAddressFilter(java.util.function.Predicate)"
        }}>{`type://ClientFactoryBuilder#ipAddressFilter(Predicate)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6106"
        }}>{`#6106`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6111"
        }}>{`#6111`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory
  .builder()
  .ipAddressFilter(ip -> { // 👈👈👈
    if ("1.2.3.4".equals(ip.getAddress().getHostAddress())) {
      return false;
    }
    return true;
  }).build();
`}</code></pre>
      </li>
      <li parentName="ul"><strong parentName="li">{`Graceful Shutdown`}</strong>{`: You can now use `}<a parentName="li" {...{
          "href": "type://GracefulShutdown:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/GracefulShutdown.html"
        }}>{`type://GracefulShutdown`}</a>{` to terminate unfinished requests
when a server stops.  `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5941"
        }}>{`#5941`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GracefulShutdown gracefulShutdown =
  GracefulShutdown
    .builder()
    .quietPeriod(Duration.ofSeconds(10))
    .timeout(Duration.ofSeconds(15))
    .toExceptionFunction((ctx, req) -> {
      return new ServerStopException();
    })
    .build();
Server.builder()
      .gracefulShutdown(gracefulShutdown);
`}</code></pre>
      </li>
      <li parentName="ul"><strong parentName="li">{`DNS Channel Strategy`}</strong>{`: You can now set `}<inlineCode parentName="li">{`datagramChannelStrategy`}</inlineCode>{` which provides an option to create a
channel per-resolution for DNS queries. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6122"
        }}>{`#6122`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6127"
        }}>{`#6127`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`DnsAddressEndpointGroup
  .builder("foo.com")
  .port(8080)
  .datagramChannelStrategy(
    DnsNameResolverChannelStrategy.ChannelPerResolution) // 👈👈👈
  .build();
`}</code></pre>
      </li>
      <li parentName="ul"><strong parentName="li">{`Map Injection for an Annotated Service`}</strong>{`: The annotated service now supports the `}<inlineCode parentName="li">{`Map<String, Object>`}</inlineCode>{`
parameter. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6058"
        }}>{`#6058`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6072"
        }}>{`#6072`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Get("/dogma/repos")
public String repos(@Param Map<String, Object> repoOptions) {...}
`}</code></pre>
      </li>
      <li parentName="ul"><strong parentName="li">{`Eureka InstanceInfo`}</strong>{`: You can now retrieve a Eureka `}<a parentName="li" {...{
          "href": "type://InstanceInfo:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/eureka/InstanceInfo.html"
        }}>{`type://InstanceInfo`}</a>{` from an `}<a parentName="li" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{`
via `}<a parentName="li" {...{
          "href": "type://InstanceInfo#instanceInfo(Endpoint):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/eureka/InstanceInfo.html#instanceInfo(com.linecorp.armeria.client.Endpoint)"
        }}>{`type://InstanceInfo#instanceInfo(Endpoint)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6056"
        }}>{`#6056`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6069"
        }}>{`#6069`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`EurekaEndpointGroup eurekaEndpointGroup = ...;
eurekaEndpointGroup.endpoints().forEach(endpoint -> {
  InstanceInfo instanceInfo = InstanceInfo.instanceInfo(endpoint); // 👈👈👈
`}</code></pre>
      </li>
      <li parentName="ul"><strong parentName="li">{`Client Preprocessor`}</strong>{`: You can now use a client `}<a parentName="li" {...{
          "href": "type://Preprocessor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Preprocessor.html"
        }}>{`type://Preprocessor`}</a>{` to customize certain properties. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6057"
        }}>{`#6057`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpPreprocessor preprocessor = (delegate, ctx, req) -> {
  ctx.setEndpointGroup(Endpoint.of("overriding-host"));
  return delegate.execute(ctx, req);
};
WebClient client =
  WebClient.builder()
           .preprocessor(preprocessor) // 👈👈👈
           .build();
`}</code></pre>
      </li>
      <li parentName="ul"><strong parentName="li">{`Backoff Builder`}</strong>{`: You can now easily create a `}<a parentName="li" {...{
          "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
        }}>{`type://Backoff`}</a>{` using builder. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5483"
        }}>{`#5483`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5488"
        }}>{`#5488`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Backoff backoff =
  Backoff.builderForExponential()
         .initialDelayMillis(100)
         .maxDelayMillis(10000)
         .multiplier(2.0)
         .build();
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Armeria now automatically registers the metrics of `}<inlineCode parentName="li">{`PooledByteBufAllocator.DEFAULT`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2633"
        }}>{`#2633`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5916"
        }}>{`#5916`}</a></li>
      <li parentName="ul">{`Server metrics now have a port tag for better monitoring and troubleshooting. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6089"
        }}>{`#6089`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6116"
        }}>{`#6116`}</a></li>
      <li parentName="ul">{`You can now see the remoteAddress in the error message when a `}<a parentName="li" {...{
          "href": "type://SessionProtocolNegotiationException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/SessionProtocolNegotiationException.html"
        }}>{`type://SessionProtocolNegotiationException`}</a>{`
is raised. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6113"
        }}>{`#6113`}</a></li>
      <li parentName="ul">{`You can now use the default `}<a parentName="li" {...{
          "href": "type://HealthCheckUpdateHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckUpdateHandler.html"
        }}>{`type://HealthCheckUpdateHandler`}</a>{` via `}<a parentName="li" {...{
          "href": "type://HealthCheckUpdateHandler#of():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckUpdateHandler.html#of()"
        }}>{`type://HealthCheckUpdateHandler#of()`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6002"
        }}>{`#6002`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`A cookie is correctly overwritten when a cookie with the same name, domain, and path is set. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5870"
        }}>{`#5870`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6126"
        }}>{`#6126`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CorsPolicyBuilder#build():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsPolicyBuilder.html#build()"
        }}>{`type://CorsPolicyBuilder#build()`}</a>{` is exposed publicly again. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6121"
        }}>{`#6121`}</a></li>
      <li parentName="ul">{`Connections do not hold references on automatically aborted requests, resulting in less memory pressure. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6108"
        }}>{`#6108`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CircuitBreakerRuleBuilder#onTimeoutException()"
        }}>{`type://CircuitBreakerRuleBuilder#onTimeoutException()`}</a>{` and `}<a parentName="li" {...{
          "href": "Type://RetryRuleBuilder#onTimeoutException()"
        }}>{`Type://RetryRuleBuilder#onTimeoutException()`}</a>{`
now work when a connection or proxy establishment is timed out. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6107"
        }}>{`#6107`}</a></li>
      <li parentName="ul">{`DNS refresh query is executed by the correct executor. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5891"
        }}>{`#5891`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6092"
        }}>{`#6092`}</a></li>
      <li parentName="ul">{`You can now choose whether to use the `}<inlineCode parentName="li">{`json_name`}</inlineCode>{` field option when transcoding HTTP/JSON to gRPC messages. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5890"
        }}>{`#5890`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6082"
        }}>{`#6082`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Automatic-Module-Name`}</inlineCode>{`s are correctly set by replacing a dot followed by a digit with an underscore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6075"
        }}>{`#6075`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6076"
        }}>{`#6076`}</a></li>
      <li parentName="ul">{`Delayed tasks for gRPC requests that have already been cancelled are not executed anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6066"
        }}>{`#6066`}</a></li>
      <li parentName="ul">{`You no longer see a false positive warning log when `}<a parentName="li" {...{
          "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
        }}>{`type://HealthCheckedEndpointGroup`}</a>{` is closing. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6063"
        }}>{`#6063`}</a></li>
      <li parentName="ul">{`An `}<a parentName="li" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{` no longer includes attributes in `}<inlineCode parentName="li">{`.toString()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6061"
        }}>{`#6061`}</a></li>
      <li parentName="ul">{`Fixed a bug where a trailing dot was included in the hostname used by SNI. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6044"
        }}>{`#6044`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6046"
        }}>{`#6046`}</a></li>
      <li parentName="ul">{`Log levels for responses are now correctly mapped based on the `}<a parentName="li" {...{
          "href": "type://SuccessFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/SuccessFunction.html"
        }}>{`type://SuccessFunction`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6042"
        }}>{`#6042`}</a></li>
      <li parentName="ul">{`Resolved the Java module conflict with the `}<inlineCode parentName="li">{`NonBlocking`}</inlineCode>{` interface. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6037"
        }}>{`#6037`}</a></li>
      <li parentName="ul">{`Unnecessary logs aren't left from an `}<inlineCode parentName="li">{`HttpResponseWrapper`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6035"
        }}>{`#6035`}</a></li>
      <li parentName="ul">{`Requests with a Content-Length header exceeding the allowed limit can now be rejected early
for improving efficiency. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5880"
        }}>{`#5880`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6032"
        }}>{`#6032`}</a></li>
      <li parentName="ul">{`You can now sample the stacktrace of `}<a parentName="li" {...{
          "href": "type://ResponseCompleteException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseCompleteException.html"
        }}>{`type://ResponseCompleteException`}</a>{` with `}<inlineCode parentName="li">{`verboseExceptionSampler`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5972"
        }}>{`#5972`}</a></li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "type://ProxyConfig:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/proxy/ProxyConfig.html"
        }}>{`type://ProxyConfig`}</a>{` now correctly respect the TTL of a DNS record. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5843"
        }}>{`#5843`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5960"
        }}>{`#5960`}</a></li>
      <li parentName="ul">{`A recovered `}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{` by the `}<a parentName="li" {...{
          "href": "type://ServiceErrorHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceErrorHandler.html"
        }}>{`type://ServiceErrorHandler`}</a>{` now correctly produces a response
content preview. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3969"
        }}>{`#3969`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5076"
        }}>{`#5076`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServerConfig#gracefulShutdownQuietPeriod():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerConfig.html#gracefulShutdownQuietPeriod()"
        }}>{`type://ServerConfig#gracefulShutdownQuietPeriod()`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ServerConfig#gracefulShutdownTimeout():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerConfig.html#gracefulShutdownTimeout()"
        }}>{`type://ServerConfig#gracefulShutdownTimeout()`}</a>{`
are now deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5941"
        }}>{`#5941`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://ServerConfig.gracefulShutdown()"
            }}>{`type://ServerConfig.gracefulShutdown()`}</a>{` instead.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`json_name`}</inlineCode>{` field option will only be used when `}<a parentName="li" {...{
          "href": "type://HttpJsonTranscodingQueryParamMatchRule#JSON_NAME:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/HttpJsonTranscodingQueryParamMatchRule.html#JSON_NAME"
        }}>{`type://HttpJsonTranscodingQueryParamMatchRule#JSON_NAME`}</a>{`
is explicitly specified via `}<a parentName="li" {...{
          "href": "type://HttpJsonTranscodingOptionsBuilder#queryParamMatchRules(Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/HttpJsonTranscodingOptionsBuilder.html#queryParamMatchRules(java.lang.Iterable)"
        }}>{`type://HttpJsonTranscodingOptionsBuilder#queryParamMatchRules(Iterable)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/6136"
        }}>{`#6136`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Apache Client 5.4.1 → 5.4.2`}</li>
      <li parentName="ul">{`BlockHound 1.0.10.RELEASE → 1.0.11.RELEASE`}</li>
      <li parentName="ul">{`Brave 6.0.3 → 6.1.0`}</li>
      <li parentName="ul">{`Brotli4J 1.17.0 → 1.18.0`}</li>
      <li parentName="ul">{`Context Propagation 1.1.1 → 1.1.2`}</li>
      <li parentName="ul">{`ControlPlane 1.0.46 → 1.0.48`}</li>
      <li parentName="ul">{`GraphQL Kotlin 8.2.1 → 8.3.0`}</li>
      <li parentName="ul">{`gRPC Java 1.68.1 → 1.70.0`}</li>
      <li parentName="ul">{`Guava 33.3.1-jre → 33.4.0-jre`}</li>
      <li parentName="ul">{`Jackson 2.18.1 → 2.18.2`}</li>
      <li parentName="ul">{`JSoup 1.18.1 → 1.18.3`}</li>
      <li parentName="ul">{`Kafka 3.8.1 → 3.9.0`}</li>
      <li parentName="ul">{`Kotlin 1.9.25 → 2.1.10`}</li>
      <li parentName="ul">{`Kotlin Coroutine 1.9.0 → 1.10.1`}</li>
      <li parentName="ul">{`Kubernetes Client 6.13.4 → 6.13.5`}</li>
      <li parentName="ul">{`Logback 1.5.12 → 1.5.16`}</li>
      <li parentName="ul">{`Micrometer 1.13.6 → 1.14.4`}</li>
      <li parentName="ul">{`Micrometer Tracing 1.3.5 → 1.4.3`}</li>
      <li parentName="ul">{`Netty 4.1.115.Final → 4.1.118.Final`}</li>
      <li parentName="ul">{`Netty Incubator IO_Uring 0.0.25.Final → 0.0.26.Final`}</li>
      <li parentName="ul">{`Prometheus 1.3.2 → 1.3.6`}</li>
      <li parentName="ul">{`Protobuf Jackson 2.6.0 → 2.7.0`}</li>
      <li parentName="ul">{`Reactor 3.6.11 → 3.7.3`}</li>
      <li parentName="ul">{`Resilience4J 2.2.0 → 2.3.0`}</li>
      <li parentName="ul">{`RxJava 3.1.9 → 3.1.10`}</li>
      <li parentName="ul">{`Sangria 4.2.2 → 4.2.5`}</li>
      <li parentName="ul">{`Scala213 2.13.15 → 2.13.16`}</li>
      <li parentName="ul">{`SLF4J2 2.0.16 → 2.0.17`}</li>
      <li parentName="ul">{`Spring6 6.2.3`}</li>
      <li parentName="ul">{`Spring Boot 3.3.5 → 3.4.3`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['0x1306e6d', 'Bue-von-hon', 'C0mbatwombat', 'Ivan-Montes', 'be-hase', 'chickenchickenlove', 'ikhoon', 'jrhee17', 'kth496', 'kwondh5217', 'minwoox', 'ohadgur', 'rickyma', 'suzuki764', 'tobias-', 'trustin', 'wreulicke', 'yzfeng2020']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      